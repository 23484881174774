<template>
	<CRow>
		<CCol sm="12">
			<TopHeadline :headerTitle="$t('pages.account.editUser')" :backTitle="$t('common.back')" :onGoBack="onBackPage">
				<template v-slot:headerRight v-if="userInfo != null">
					<button type="button" name="reset-password" class="btn btn-danger" @click="onResetPassword" v-if="isAllowResetPassword">
						{{ $t('pages.account.resetpassword') }}
					</button>
					<button type="button" name="confirm-active-inactive" class="btn btn-success" @click="onActivateAccount" v-if="userInfo.isClosed">Kích hoạt</button>
					<button type="button" name="confirm-active-inactive" class="btn btn-success" @click="onDeactivateAccount" v-if="!userInfo.isClosed">Hủy kích hoạt</button>
				</template>
			</TopHeadline>
			<CRow>
				<CCol sm="6">
					<AccountGeneralInfo :userInfo="userInfo" 
					@verifySuccess="onAccountHasChanged" 
					@rejectSuccess="onAccountHasChanged"
					@updateRoleSuccess="onAccountHasChanged"
					@updateInfoSuccess="onAccountHasChanged"
					></AccountGeneralInfo>
				</CCol>
				<CCol sm="6">
					<CCard class="card-secondary card-outline">
						<CCardHeader>
							<h5>Kho hình ảnh</h5>
						</CCardHeader>
						<CCardBody>
							<div>Ảnh avatar</div>
							<div class="d-flex" style="justify-content: center;" v-if="userInfo != null && userInfo.avatarImage != null">
								<img class="avatar" :src="userInfo.avatarImage.imageUrl"/>
							</div>
							<hr/> 
							<div>Ảnh CMND/CCCD</div>
							<div v-if="userInfo != null && userInfo.idCardImages != null">
								<div v-for="item in userInfo.idCardImages" v-bind:key="item.id" style="display:inline-block;margin-right:1rem">
									<!-- <img :src="item.imageUrl" width="175"/> -->

									<a :href="item.fullSizeImageUrl" target="_blank">
													<img :src="item.thumbImageUrl" width="175"/>
												</a>

								</div>
							</div>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			

			<Confirmation ref="confirmation"></Confirmation>
			<ResetPassword ref="resetpassword"></ResetPassword>
			<Loading :active="loading"
                 :is-full-page="true"/>
		</CCol>
	</CRow>
</template>

<script>
	import { mapGetters, mapState,mapActions } from 'vuex';
    import i18n from '@/plugins/i18n';
	import TopHeadline from '@/components/TopHeadline.vue';
	import Confirmation from '@/components/Confirmation.vue';
    import ResetPassword from '@/components/ResetPassword.vue';
	import Loading from 'vue-loading-overlay';
	import AccountGeneralInfo from './_AccountGeneralInfo.vue'

	import _ from 'lodash'
	import * as yup from 'yup'

	const userInfoSchema = yup.object().shape({
        fullName: yup.string().required("Nhập tên đầy đủ"),
		phoneNumber: yup.string().required("Nhập số điện thoại"),
        address: yup.string().required("Nhập địa chỉ"),
    });

	const editRoleSchema = yup.object().shape({
		selectedRole: yup.string().required("Bạn hãy chọn cấp bậc")
	});
	const initialEditRoleErrors = {
			selectedRole: '',
		};

	export default {
        name: 'Detail',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },
                fields: [                      // Array of column object data                  
                    { key: "cmnd_image", label: i18n.t('pages.account.frontAndBackImage') }                    
                ],
				isEditingRole: false,
				loading: false,
				userInfo: null,
				editingErrors: {
                    fullName: '',
                    phoneNumber: '',
					address: '',
                },

				editRole: {
					userId: 0,
					selectedRole: '',
				},
				editingRoleErrors: initialEditRoleErrors,
			};
		},
        components: {
            Confirmation,
			TopHeadline,
            ResetPassword,
			Loading,
			
			AccountGeneralInfo
        },
        computed: {            
			...mapGetters('profile', ['pictureModels']),
			...mapGetters('auth', ['user']),
			...mapState('role', ['roles']),
            isAllowResetPassword() {
                if (this.$store.state.auth.user.role === 'admin')
                    return true;               
                return false;
            },

			
        },  
		methods: {        
			...mapActions('role', ['getAllRoles']),              
            onBackPage() {
				
				this.$router.push('/users/list/');
			},
			validate(field) {
                userInfoSchema.validateAt(field, this.values)
                    .then(() => this.editingErrors[field] = '')
                    .catch((err) => {
                        this.editingErrors[err.path] = err.message
                        console.log('validate error', err);
                    });
            },

			showgender(gender) {
				if (gender === 1)
					return i18n.t('pages.account.male');
                else if (gender === 2)
                    return i18n.t('pages.account.female');
				else 
					return '';
			},
            formatDate(date) {
                if (date === null || date === undefined)
                    return '';
                return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
            },
			async onUpdateAccount() {
				try {
                    await userInfoSchema.validate(this.userInfo, { abortEarly: false });
                    this.editingErrors = {};
                }
                catch (err) {
                    _.forEach(err.inner, error => {
                        this.editingErrors[error.path] = error.message;
                        console.log("Validation error", { path: error.path, message: error.message })
                    })
                    return;
                }


				console.log('Start update ',this.userInfo);
				var response = await this.$accountService.updateAccount(this.userInfo);
				if (response.data == true) {
					this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.account.noticeUpdateSuccessful') }, {root: true});
                    //this.$router.push('/users/list/');
                }
			},
			onActivateAccount() {
				if(this.userInfo == null || this.userInfo.id <=0)
					return;	
                this.$refs.confirmation.show(i18n.t('common.confirmMakeChange'), async () => {

                    var retVal = await this.$accountService.activateAccount(this.userInfo.id);
                    if (retVal == true) {
						await this.loadUser();
                        this.$store.dispatch('toast/displayNotification', { text: i18n.t('common.successfully') });
                    }
                });
			},
			onDeactivateAccount() {
				if(this.userInfo == null || this.userInfo.id <=0)
					return;	
                this.$refs.confirmation.show(i18n.t('common.confirmMakeChange'), async () => {

                    var retVal = await this.$accountService.deactivateAccount(this.userInfo.id);
                    if (retVal == true) {
						await this.loadUser();
                        this.$store.dispatch('toast/displayNotification', { text: i18n.t('common.successfully') });
                    }
                });
			},


			onVerify() {
				let uid = 0;
				if(this.userInfo != null)
					uid = this.userInfo.id;
					
				if(uid <= 0)
					return;
                this.$refs.confirmation.show("Số CMND này là HỢP LỆ. Bạn có chắc chắn?", async () => {
					var retVal = await this.$accountService.changeAccountProfileStatus({ userId: uid, newStatus: 1 });
                    if (retVal == true) {
                        this.$store.dispatch('toast/displayNotification', { text: i18n.t('common.successfully') });
                    }
                });
                
			},
			onRejectVerification() {
                let uid = 0;
				if(this.userInfo != null)
					uid = this.userInfo.id;
					
				if(uid <= 0)
					return;
                this.$refs.confirmation.show("Số CMND này KHÔNG hợp lệ. Bạn có chắc chắn?", async () => {

                    var retVal = await this.$accountService.changeAccountProfileStatus({ userId: uid, newStatus: 2 });
                    if (retVal == true) {
                        this.$store.dispatch('toast/displayNotification', { text: i18n.t('common.successfully') });
                    }
                });
			},
			onResetPassword() {
                let _profileId = this.$route.params.id
                this.$refs.confirmation.show(i18n.t('pages.account.confirmresetpassword'), async () => {

                    this.$refs.resetpassword.show(_profileId);
                });
            },
			async onAccountHasChanged() {
				await this.loadUser();
			},

			async loadUser() {
				var userId = this.$route.params.id;
			
				if(userId > 0)
				{
					this.loading = true;
					var accountDetailResponse = await this.$store.$accountService.getAccountProfileById(userId);
		
					if(!accountDetailResponse.success) 
					{
						this.loading = false;
						this.userInfo = null;
						return;
					}
					
					var accountMediaDetailResponse = await this.$store.$accountService.getAllIdentityIdCardImages(userId);
					var avatarResponse = await this.$store.$accountService.getAvatarToUser({userId: userId});
					
					var uInfo = accountDetailResponse.data;
					if(accountMediaDetailResponse.success == true) {
						uInfo.idCardImages = accountMediaDetailResponse.data;
					}

					if(avatarResponse.success == true) {
						uInfo.avatarImage = avatarResponse.data;
					}
					this.userInfo = uInfo;
					this.loading = false;
				}
			},

		
		},
		async mounted() {        
			if(this.roles == null || this.roles.length == 0) {
				this.getAllRoles();
			}  
			await this.loadUser();
		}
	}
</script>
<style scoped>
	img {
		max-width: 100%;
		max-height: 100%;
	}
	.avatar {
		width: 150px;
		height: auto;
	}

</style>