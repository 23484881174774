<template>
	<CRow>
		<CCol sm="12">
			
			<ul class="container" v-if="ancestors != null">
				<li  class="flex flex1" v-if="ancestors.length > 0">
					<CIcon name="cil-list-numbered" style="color: #636f83;background-color: none;width:2rem;height: 2rem;"/>
				</li>
				<template v-for="(item, idx) in ancestors">
					
					<li class="flex flex1" v-bind:key="item.id">
						<a href="javascript:void(0)" @click="onViewDetailUser(item)">{{item.userName}}</a><br/>
						<span style="font-size: 0.8em">{{item.role != null ? item.role.displayName : ''}}</span>
						<div>
					</div>
					</li>
					<li v-bind:key="'separator_' + item.id" style="padding-top:0.5rem" v-if="idx < ancestors.length-1">
						<CIcon name="cil-arrow-circle-right" style="color: green;background-color: none;"></CIcon>
					</li>
				</template>
			
			</ul>
		</CCol>
	</CRow>
</template>

<script>

    import i18n from '@/plugins/i18n'
	import { mapGetters, mapActions } from 'vuex'

	export default {
        name: 'UserHierachy',
		props: {
            ancestors: {
                default: null,
                type: Array
            }
        },
		data() {
			return {
			
			}
		},
        components: {
        },
        computed: {           
        },  
		methods: {
			onViewDetailUser(item){
				this.$router.push('/users/detail/' + item.id);
			}
		},		
		mounted(){
			console.log('ans', JSON.stringify(this.ancestors))
		}
	}
</script>

<style scoped>
.container {
  font-family: arial, sans-serif;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

.flex {
  /* background: #6ab6d8; */
  padding: 10px;
  margin-bottom: 50px;
  /* border: 3px solid #2e86bb; */
  /* color: white; */
  color:green;
  font-size: 14px;
  text-align: center;
  position: relative;
}

.flex a {
  color: orangered;
}

.flex::after {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 100%;
  margin-top: 10px;
  width: 100%;
  color: #333;
  font-size: 12px;
}

.flex1 {
  flex-basis: auto;
}

.flex1::after {
  /* content: "auto"; */
}

.flex2 {
  flex-basis: max-content;
}

.flex2::after {
  content: "max-content";
}

.flex3 {
  flex-basis: min-content;
}

.flex3::after {
  content: "min-content";
}

.flex4 {
  flex-basis: fit-content;
}

.flex4::after {
  content: "fit-content";
}

.flex5 {
  flex-basis: content;
}

.flex5::after {
  content: "content";
}


</style>