<template>
    <CModal title="Tập đoàn Hoàng Minh Trung"
            color="success"
            :show="showModal" @close="onCloseModal">

        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <strong>{{ $t('pages.account.resetpassword') }}</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CForm>                                       
                            <CInput type="password" :placeholder="$t('pages.account.enterpasswordNew')"
                                    :is-valid="validatorPassword" v-model="newPassword" required horizontal autocomplete="off">
                                <template #invalid-feedback>
                                    <div class="invalid-feedback">
                                        {{ $t('pages.account.passwordNew') }}, {{ $t('pages.account.limitPasswordLength') }}
                                    </div>
                                </template>
                                <template #label>
                                    <div class="col-sm-4 col-form-label">
                                        {{ $t('pages.account.passwordNew') }}
                                        <small class="requiredInput">(*)</small>
                                    </div>
                                </template>
                            </CInput>

                            <CInput type="password" :placeholder="$t('pages.account.enterRetypePassword')"
                                    :is-valid="validatorPassword" v-model="retypeNewPassword" required horizontal autocomplete="off">
                                <template #invalid-feedback>
                                    <div class="invalid-feedback">
                                        {{ $t('pages.account.enterRetypePassword') }}, {{ $t('pages.account.limitPasswordLength') }}
                                    </div>
                                </template>
                                <template #label>
                                    <div class="col-sm-4 col-form-label">
                                        {{ $t('pages.account.passwordConfirmation') }}
                                        <small class="requiredInput">(*)</small>
                                    </div>
                                </template>
                            </CInput>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>       
       
        <template v-slot:footer>
            <CButton type="button" color="primary" @click="onSave">{{ $t('common.save') }}</CButton>
            <CButton type="button" color="secondary" @click="onCloseModal">{{ $t('common.close') }}</CButton>
        </template>

        <Confirmation ref="confirmation"></Confirmation>
    </CModal>    
</template>

<script>   
    import { mapActions } from 'vuex'
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'

    export default {
        name: 'ResetPassword',
        props: {
            items: Array,            
        },
        data() {
            return {
                showModal: false,
                profileId: 0,
                newPassword: '',
                retypeNewPassword: ''
            };
        },
        components: {
            Confirmation
        },
        methods: {
            ...mapActions('profile', ['resetPassword']),

            show(userId) {                
                this.showModal = true;
                this.profileId = userId;                
            },
            hide() {
                this.showModal = false;
            },
            validatorPassword(val) {
                return val ? val.length > 5 : false
            },
            async onSave() {
                if (this.newPassword === '') {
                    this.$store.dispatch('alert/error', i18n.t('pages.account.enterpasswordNew'));
                    return;
                }
                else if (!this.validatorPassword(this.newPassword)) {
                    this.$store.dispatch('alert/error', i18n.t('pages.account.limitPasswordLength'));
                    return;
                }

                if (this.newPassword != this.retypeNewPassword) {
                    this.$store.dispatch('alert/error', i18n.t('pages.account.paswordNotMatch'));
                    return;
                }

                var retVal = await this.resetPassword({ profileId: this.profileId, newPassword: this.newPassword, retypePassword: this.retypeNewPassword });
                if (retVal == true) {
                    this.newPassword = '';
                    this.retypeNewPassword = '';
                    this.hide();                   

                    //this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.account.noticeRestePasswordSuccessful') });
                }
                //else {
                //    this.$store.dispatch('alert/error', i18n.t('pages.account.passwordchangefailed'));
                //}
            },
            onCloseModal() {
                this.hide();                
            }
        }
    }
</script>
