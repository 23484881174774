<template>
	<div>		
		<Accordion :headerTitle="$t('pages.account.accountInfomation')" v-if="userInfo != null" :showFooter="true">
			<template v-slot:body>
				<CRow>
					<CCol sm="12">
						<div ref="generalInfo"> </div>
						<UserHierachy :ancestors="userInfo.ancestors" />

						<div class="form-group form-row" style="margin-top:-30px;" v-if="userInfo.role != null && userInfo.role.name=='agent' && user != null && user.role=='admin'">
								<div class="col-md-3">
									<CButton color="success" variant="outline" class="mx-3" @click="startChangeParent">Đổi cấp cha</CButton>
								</div>
								<div class="col-md-9">
									<CCard class="card-secondary card-outline" v-if="isEditingParent">
										<CCardHeader>
											<strong>Chọn cấp cha mới (Sale)</strong>
										</CCardHeader>
										<CCardBody>
											<CForm>
												<SearchableSaleDropdown :userArray="saleUsers" @itemSelected="onSelectNewParent"></SearchableSaleDropdown>
												<div class="form-group form-row">
													<div class="col-md-3">

													</div>
													<div class="col-md-9" style="margin-top:5px">
														<CButton type="button" class="mr-2" color="primary" @click="handleUpdateParent">{{ $t('common.ok') }}</CButton>
														<CButton type="button" color="secondary" @click="handleCancelEditParent">Bỏ qua</CButton>
													</div>
												</div>
											</CForm>
										</CCardBody>
									</CCard>
								</div>
							</div>			


						<CForm v-if="viewMode == 'view'">
							<div>
								<div class="form-group form-row">
									<div class="col-md-3">
										<label>{{ $t('common.userName') }}</label>
									</div>
									<div class="col-md-9">
										<span>{{userInfo.userName}}</span>
									</div>
								</div>				

								<div class="form-group form-row">
									<div class="col-md-3">
										<label>Cấp bậc</label>
									</div>
									<div class="col-md-9">
										<span>{{userInfo.role != null ? userInfo.role.displayName: 'N/A'}}</span>
									</div>
								</div>

								

									
								<!-- <div class="form-group form-row">
									<div class="col-md-3">
										<label>Cấp bậc</label>
									</div>
									<div class="col-md-9">
										<span>{{userInfo.role != null ? userInfo.role.displayName: 'N/A'}}</span>
										<CButton color="success" variant="outline" class="mx-3" @click="startEditUserRole">Đổi cấp</CButton>
									</div>

									<div class="test">
										<SearchableSaleDropdown></SearchableSaleDropdown>
									</div>
								</div>

								<div class="form-group form-row">
									<div class="col-md-3">
									</div>
									<div class="col-md-9">
										<CCard class="card-secondary card-outline" v-if="isEditingRole">
											<CCardHeader>
												<strong>Thay đổi cấp bậc</strong>
											</CCardHeader>
											<CCardBody>
												<CForm>
													<CSelect horizontal :options="roleOptions" :value.sync="editRole.selectedRole"
															 :isValid="!editingRoleErrors.selectedRole" :invalidFeedback="editingRoleErrors.selectedRole">
														<template #label>
															<div class="col-md-3 col-form-label">
																Cấp bậc
																<small class="requiredInput">(*)</small>
															</div>
														</template>
													</CSelect>

													<div class="form-group form-row">
														<div class="col-md-3">

														</div>
														<div class="col-md-9">
															<CButton type="button" class="mr-2" color="primary" @click="handleUpdateUserRole">{{ $t('common.ok') }}</CButton>
															<CButton type="button" color="secondary" @click="handleCancelEditRole">Bỏ qua</CButton>
														</div>
													</div>
												</CForm>
											</CCardBody>
										</CCard>
									</div>
								</div> -->

								<div class="form-group form-row">
									<div class="col-md-3">
										<label>{{ $t('pages.account.fullName') }}</label>
									</div>
									<div class="col-md-9">
										<p>{{ userInfo.fullName }}</p>
									</div>
								</div>
								<div class="form-group form-row">
									<div class="col-md-3">
										<label>{{ $t('common.email') }}</label>
									</div>
									<div class="col-md-9">
										<p>{{ userInfo.email }}</p>
									</div>
								</div>
								<div class="form-group form-row">
									<div class="col-md-3">
										<label>{{ $t('common.phone') }}</label>
									</div>
									<div class="col-md-9">
										<p>{{ userInfo.phoneNumber }}</p>
									</div>
								</div>
								<div class="form-group form-row">
									<div class="col-md-3">
										<label>{{ $t('common.address') }}</label>
									</div>
									<div class="col-md-9">
										<p>{{ userInfo.address }}</p>
									</div>
								</div>
								<div class="form-group form-row">
									<div class="col-md-3">
										<label>CMND/CCCD</label>
									</div>
									<div class="col-md-9">
										<div v-if="!userInfo.cmnd">
											<span>N/A</span>
											<span class="not-verified">Chưa xác minh</span>
										</div>
										<div v-else>
											<span>{{ userInfo.cmnd }}</span>
											<span class="not-verified" v-if="userInfo.profileStatus == null || userInfo.profileStatus == 0">Chưa xác minh</span>
											<span class="verified" v-if="userInfo.profileStatus == 1">Đã xác minh (hợp lệ)</span>
											<span class="not-verified" v-if="userInfo.profileStatus == 2">Đã từ chối xác minh</span>
										</div>


									</div>
								</div>
								<div class="form-group form-row" v-if="canVerify">
									<div class="col-md-3">
									</div>
									<div class="col-md-9">
										<CButton color="success" variant="outline" class="mx-3" @click="onVerify">Xác nhận hợp lệ</CButton>
										<CButton color="success" variant="outline" class="mx-3" @click="onRejectVerification">Không hợp lệ</CButton>
									</div>
								</div>


								<div class="form-group form-row">
									<div class="col-md-3">
										<label>{{$t('pages.account.dayOfBirth')}}</label>
									</div>
									<div class="col-md-9">
										<span>
											{{formatDate(userInfo.dayOfBirth)}}
										</span>
									</div>
								</div>


								<div class="form-group form-row">
									<div class="col-md-3">
										<label>Giới tính</label>
									</div>
									<div class="col-md-9">
										<span>
											{{userInfo.gender == 1 ? 'Nam':'Nữ'}}
										</span>
									</div>
								</div>
							</div>
						</CForm>


						<CForm v-if="viewMode == 'edit'">
							<CInput :label="$t('pages.account.fullName')" v-model="values.fullName"
									horizontal
									:isValid="!errors.fullName" :invalidFeedback="errors.fullName">
							</CInput>
							<CInput :label="$t('common.phone')" v-model="values.phoneNumber" horizontal
									:isValid="!errors.phoneNumber" :invalidFeedback="errors.phoneNumber">
							</CInput>

							<CInput :label="$t('common.address')" v-model="values.address"
									horizontal
									:isValid="!errors.address" :invalidFeedback="errors.address">
							</CInput>
						</CForm>
					</CCol>

					<Confirmation ref="confirmation"></Confirmation>
				</CRow>

			</template>
			<template #footer>
				<CButton v-if="viewMode === 'view'" component="a" color="link" role="button" @click="onBeginEdit()">Thay đổi</CButton>
				<CButton v-if="viewMode === 'edit'" color="primary" @click="onSaveGeneralInfo()" class="mx-2">Lưu lại</CButton>
				<CButton v-if="viewMode === 'edit'" color="danger" @click="onCancelEdit()">Bỏ qua</CButton>
			</template>
		</Accordion>			
		<Accordion :headerTitle="$t('pages.agent.agentListing')" v-if="userInfo != null && userInfo.role != null && userInfo.role.name == 'sale'" expand>
			<template v-slot:body>
				<CDataTable :items="allAgents"
							:fields="agentFields"
							:noItemsView="{ noItems: $t('common.nodatafound') }"
							hover
							striped
							border
							small
							fixed							
							pagination
							:items-per-page="itemsPerPage"
							@pagination-change="onItemsPerPageChange"
							:items-per-page-select="{
                                label : $t('common.recordsperpage'),
                                values : [10,20,50,100]
                                }">
					<template #footer v-if="allAgents != null && allAgents.length > 0">
						<tfoot>
							<tr>
								<td :colspan="8">
									<span>{{ $t('common.totalRecords') }}: </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{ allAgents == null ? 0: allAgents.length }}</span>
								</td>
							</tr>
						</tfoot>
					</template>								
				</CDataTable>
			</template>
		</Accordion>			
	</div>
</template>

<script>
import {stringToASCII} from '@/helpers/func'
	import { mapGetters, mapActions, mapState } from 'vuex'
	import i18n from '@/plugins/i18n'
    import { Role } from '@/helpers/role';
    import Accordion from '@/components/Accordion.vue'
    import UserHierachy from '@/components/UserHierachy.vue'
    import Confirmation from '@/components/Confirmation.vue';
	import SearchableSaleDropdown from '@/components/SearchableSaleDropdown.vue';
	import _ from 'lodash'
	import * as yup from 'yup'

	const userInfoSchema = yup.object().shape({
        fullName: yup.string().required("Nhập tên đầy đủ"),
		phoneNumber: yup.string().required("Nhập số điện thoại"),
        address: yup.string().required("Nhập địa chỉ"),
    });

    const initialValues =  {
					fullName: '',
					phoneNumber: '',
					address: '',
				};
	const initialErrors = {
					fullName: '',
					phoneNumber: '',
					address: '',
				};


	const editRoleSchema = yup.object().shape({
		selectedRole: yup.string().required("Bạn hãy chọn cấp bậc")
	});
	const initialEditRoleErrors = {
			selectedRole: '',
		};

	export default {
        name: 'AccountGeneralInfo',
        props: {
            userInfo: {
                default: null,
            },
            loading: {
                default: false,
                type:Boolean
            }
        },
        emits: ['verifySuccess', 'rejectSuccess', 'updateRoleSuccess', 'updateInfoSuccess'],

        data() {
            return {
                viewMode: 'view',

				values: initialValues,
				errors: initialErrors,

                isEditingRole: false,
				isEditingParent: false,
				editRole: {
					userId: 0,
					selectedRole: '',
				},
				editingRoleErrors: initialEditRoleErrors,
				inputInfo: null, //this.userInfo,
                agentFields: [
                    { key: 'name', label: i18n.t('pages.agent.agentName') },
                    //{ key: 'ownerName', label: i18n.t('pages.agent.ownerName') },                   
                    { key: 'phone', label: i18n.t('common.phone') },
                    { key: 'address', label: i18n.t('common.address') }
                ],
				saleUsers: [],
				newParent: null,
            }
        },
        components: {
            Accordion,
            UserHierachy,
            Confirmation,
			SearchableSaleDropdown
        },
        computed: {            
			...mapGetters('auth', ['user']),
            ...mapState('agent', ['allAgents']),
            ...mapState('role', ['roles']),
            roleOptions(){
				let options = [];
				
				if(this.roles != null)
				{
					options = this.roles
					.filter(item => item.name != 'admin' && item.name != 'user')
					.map(item => {
						return {value: item.name, label: item.displayName};
					});
				}
				options.splice(0,0,
					{
						value: '', 
						label: 'Chọn cấp bậc'
					});

				return options;
			} ,
			canVerify() {
				if (this.userInfo != null && this.userInfo.cmnd != null && this.userInfo.cmnd != '' && this.userInfo.profileStatus != null && this.userInfo.profileStatus == 0) {
                    return true;
                }
                return false;
            }
		},		
		methods: {
            ...mapActions('agent', ['getAgentBySale']),

			onBeginEdit(){
				Object.assign(this.values, this.userInfo);
				this.viewMode = "edit";
                this.$refs.generalInfo.scrollIntoView({ behavior: "smooth" })
			},
			onCancelEdit(){
				this.viewMode = "view";
				this.values = {...initialValues};
				this.errors = {...initialErrors};
			},

			async validateGeneralInfo() {
				console.log("CONTEXT VALIDATE ALL",this) 
				try {
					this.errors = {...initialErrors};
                    await userInfoSchema.validate(this.values, { abortEarly: false });
					return true;
                }
                catch (err) {
                    _.forEach(err.inner, error => {
                        this.errors[error.path] = error.message;
                    })
					console.log("all errors", this.errors)
                    return false;
                }
			},
			async onSaveGeneralInfo(){
				var isValid = await this.validateGeneralInfo();
				if(!isValid)
					return;
				
				console.log('Start update ',this.userInfo);
				var response = await this.$accountService.updateAccount(this.values);
				if (response.data == true) {
                    this.onCancelEdit();
					this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.account.noticeUpdateSuccessful') }, {root: true});
                    this.$emit('updateInfoSuccess');
                }
					
			},
			formatDate(value) {
                return this.$moment(value).format('DD-MM-YYYY');
			},

            onVerify() {
				let uid = 0;
				if(this.userInfo != null)
					uid = this.userInfo.id;
					
				if(uid <= 0)
					return;
                this.$refs.confirmation.show("Số CMND này là HỢP LỆ. Bạn có chắc chắn?", async () => {
					var retVal = await this.$accountService.changeAccountProfileStatus({ userId: uid, newStatus: 1 });
                    if (retVal == true) {
                        this.$store.dispatch('toast/displayNotification', { text: i18n.t('common.successfully') });
                        this.$emit('verifySuccess');
                    }
                });
                
			},
			onRejectVerification() {
                let uid = 0;
				if(this.userInfo != null)
					uid = this.userInfo.id;
					
				if(uid <= 0)
					return;
                this.$refs.confirmation.show("Số CMND này KHÔNG hợp lệ. Bạn có chắc chắn?", async () => {

                    var retVal = await this.$accountService.changeAccountProfileStatus({ userId: uid, newStatus: 2 });
                    if (retVal == true) {
                        this.$store.dispatch('toast/displayNotification', { text: i18n.t('common.successfully') });
                        this.$emit('rejectSuccess');
                    }
                });
			},
			startChangeParent(){
				this.isEditingParent = true;
				this.newParent = null;
			},
			handleCancelEditParent(){
				this.editingRoleErrors = {...initialEditRoleErrors};
				this.isEditingParent = false;
				this.newParent = null;
			},
            startEditUserRole() {
				console.log('start edit role', {user: this.userInfo})
				if(this.userInfo!= null) {
					this.editRole.userId = this.userInfo.id;
					if(this.userInfo.role !== undefined)
						this.editRole.selectedRole = this.userInfo.role.name;
				}
				console.log('start edit role end', {roe: this.editRole})
				this.isEditingRole = true;
			},
			finishEditUserRole() {  
				this.isEditingRole = false;
			},

			onSelectNewParent(newParent){
				console.log('new parent selected', newParent)
				this.newParent = newParent;
			},

			async handleUpdateParent(){
				if(this.newParent == null || this.newParent.id == null || this.newParent.id <= 0)
				{
					alert('Hãy chọn 1 account');
					return;
				}

				var result = await this.$accountService.changeParent({
						userId: this.userInfo.id, 
						newParentId: this.newParent.id
					});
				if(result != null && result === true) {
						this.$store.dispatch('toast/displayNotification', { text: "Thay đổi cấp trên thành công" }, { root: true });
						window.location.reload();
					
				}else {
					this.$store.dispatch('alert/error', "Không thể thay đổi cấp trên được. Bạn hãy liên hệ quản trị viên hệ thống", { root: true });
				}

			},
			async handleUpdateUserRole(){
				try {
					this.editingRoleErrors = {...initialEditRoleErrors};
                    await editRoleSchema.validate(this.editRole, { abortEarly: false });
                }
                catch (err) {
                    _.forEach(err.inner, error => {
                        this.editingRoleErrors[error.path] = error.message;
                    })
                    return;
                }
				console.log('begin update role', this.editRole);
				var result = await this.$accountService.assignUserRole({
						userId: this.editRole.userId, 
						roleName: this.editRole.selectedRole
					});
				if(result != null && result.id > 0) {
					if(this.userInfo != null && this.userInfo.id == this.editRole.userId) {
						this.userInfo.role = result;
						this.$store.dispatch('toast/displayNotification', { text: "Cập nhật cấp bậc thành công" }, { root: true });
                        this.$emit('updateRoleSuccess');
						this.editingRoleErrors = {...initialEditRoleErrors};
						this.finishEditUserRole();
					}
					else{
						this.$store.dispatch('alert/error', "Không thể cập nhật cấp bậc mới", { root: true });
					}
				}else {
					this.$store.dispatch('alert/error', "Không thể cập nhật cấp bậc mới", { root: true });
				}
			},
			handleCancelEditRole(){
				this.editingRoleErrors = {...initialEditRoleErrors};
				this.finishEditUserRole();
			},
			init() {                
				this.inputInfo = this.userInfo;

                if (this.inputInfo != null && this.inputInfo.role != null && this.inputInfo.role.name == Role.Sale) {
                    this.getAgentBySale({ managerId: this.inputInfo.id });
                }
			},
            onItemsPerPageChange(item) {
                localStorage.setItem('agentItemsPerPage', item)
            },            
		},
		watch: {
			userInfo: function (newVal) {
				//this.inputInfo = newVal;
				this.init();                
            }
		},
		async mounted() {             
			try {
				var data = await this.$accountService.getUsersByRole({ roleName: 'sale' });
				var json = data.data;
				this.saleUsers = json;
				if(this.saleUsers != null) {
					this.saleUsers.map(u => u.nameWithoutDiacritics = stringToASCII(u.fullName).toLowerCase())
				}
				console.log(json);
			}
			catch(ex){
				console.log('error', ex);
				this.saleUsers = [];
			}           
		},
		created() {
            let agentItemsPerPage = parseInt(localStorage.getItem('agentItemsPerPage'));
            if (isNaN(agentItemsPerPage))
                agentItemsPerPage = 10;
            this.itemsPerPage = agentItemsPerPage;
        },
	}
</script>
<style scoped>
.not-verified {
    margin-left: 2rem;
  border-radius: 25px;
  background: #cf2405;
  padding-top: 3px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 100px;
  color:aliceblue;
}

.verified {
    margin-left: 2rem;
  border-radius: 25px;
  background: #198503;
  padding-top: 3px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 100px;
  color:aliceblue;
}
</style>
